import React, { Component } from "react"


class Article extends Component {
    render() {
        return (
            <article>
                {this.props.children}
            </article>
        )
    }
}

export default Article
