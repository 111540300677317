import React,  { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import parse from "html-react-parser"

import Img from "gatsby-image";
import Layout from "../components/layout"
import Tags from "../components/tagList"
import SEO from "../components/seo"
import Article from "../components/article";


class SingleProject extends Component {


    render() {
        const project = this.props.data.wordpressWpProjects

        return (

  <Layout>
      <SEO
          title={project.yoast_meta.yoast_wpseo_title}
          description={project.yoast_meta.yoast_wpseo_metadesc}
          keywords={project.tags.map(tag => tag.name)}

      />

      <Article>
                        <Img
                            alt={project.featured_media.alt_text}
                            fluid={project.featured_media.localFile.childImageSharp.fluid}

                        />
          <div className="metadata">
              <Tags tags={project.tags} />
          </div>

          <h2
          dangerouslySetInnerHTML={{ __html: project.title }}

      />



      {parse(project.content)}
      </Article>
                </Layout>

        )
    }
}

SingleProject.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default SingleProject

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpProjects(id: { eq: $id }) {
      wordpress_id
      title
      content
      date
      tags {
        id
        name
        slug
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        
        
      }
      featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

